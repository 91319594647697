// 開発環境用変数
export const environment = {
  production: false,
  apiUrl: 'https://dev.lot.tsite.jp/api',
  cdnPath: 'https://stg.img.tsite.jp/resources/lot',
  gtmCategory: 'Test',
  garaponBannerJsonPath:
    'https://stg.img.tsite.jp/resources/tsite/lot/json/garapon_bnr.json',
  bannarPath: 'https://stg.img.tsite.jp/resources/tsite/lot/img/',
  domainPath: 'https://dev.lot.tsite.jp',
  maintenanceJson: 'https://stg.img.tsite.jp/system/shirase/maintenance.json',
  commonResourcesPath: 'https://img.tsite.jp/resources/common', // NOTE: 検証でも本番を向く
};
